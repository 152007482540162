
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { merge } from 'lodash';
import { useAppSelector } from 'stores';
import { userApi } from './api/userApi';

export interface AuthState {
  token?: string | null
  code?: string | null 
}

const initialState: AuthState = {}
export const logout = createAction<void>('logout')

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCode(state, action: PayloadAction<string>) {
      state.code = action.payload
    }, 
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state) => {
        merge(state, {}, initialState)
      })

      .addMatcher(userApi.endpoints.checkAvailability.matchFulfilled, (state, action) => {

        if (!action.payload.available) {
          state.code = null
          state.token = null
        }

      })
      .addMatcher(userApi.endpoints.checkAvailability.matchRejected, (state) => {
        state.code = null
        state.token = null
      })
      .addMatcher(userApi.endpoints.codeToken.matchFulfilled, (state, action) => {
        state.token = action.payload.token
      })
      .addMatcher(userApi.endpoints.signin.matchFulfilled, (state, action) => {
        if (action.payload.token) {
          state.token = action.payload.token
        }
      })
      ;
  }
})


export const {
  setCode, 
} = authSlice.actions
export const useAppAuthentication = () => useAppSelector(state => state.auth)
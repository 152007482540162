import GroupePropress from 'assets/images/groupepropress-v.svg'
import dayjs from 'dayjs';
const Footer = () => {
    return (
        <footer className="pt-4 my-md-5 pt-md-5 border-top bg-primary">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md logo">
                        <img className="mb-2 img img-fluid " src={GroupePropress} alt="" />
                    </div>
                    <div className="col-6 col-md">
                        {/* <h5>CONTACTEZ-NOUS</h5> */}
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="mailto:contact@propress.fr">Nous contactez</a></li>
                        </ul>
                    </div>
                    <div className="col-6 col-md">
                        {/* <h5>INFORMATION</h5> */}
                        <ul className="list-unstyled text-small">
                            <li className="mb-1"><a className="link-secondary text-decoration-none" href="https://cgu.kioskpress.fr/" target="_blank" rel="noreferrer" >Mentions légales </a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='text-center my-1'>
                <small className="d-block mb-3">© Groupe ProPress {dayjs().format('YYYY')}</small>
            </div>
        </footer>
    )
};

export default Footer;

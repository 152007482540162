import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import { userApi } from "..//api/userApi";
import { logout,  setCode } from "../authSlice";
import { kioskApi } from "../api/kioskApi";
import { aubureauApi } from "stores/api/aubureauApi";

export const accountMiddleware = createListenerMiddleware();
// Add one or more listener entries that look for specific actions.
// They may contain any sync or async logic, similar to thunks.
accountMiddleware.startListening({
    matcher: isAnyOf(logout, setCode,  ),
    effect: async (action, listenerApi) => {
        if (logout.match(action)) {

            listenerApi.dispatch(userApi.util.invalidateTags(['Profile']))
            listenerApi.dispatch(kioskApi.util.invalidateTags(['Kiosk']))
        }
        else if (setCode.match(action)) {

            listenerApi.dispatch(kioskApi.util.invalidateTags(['Kiosk']))
            if (action.payload) {
                listenerApi.dispatch(userApi.endpoints.checkAvailability.initiate(action.payload))
            } else {
                listenerApi.dispatch(userApi.util.invalidateTags(['Profile']))

            }
        } 
    },
});


import dayjs from "dayjs";
import { useRef } from "react";
import imageNotFound from "../assets/images/image-not-found.png";
import { Issue } from '../stores/types'

interface Props {
  issue: Issue
  onClick?: (issue: Issue) => void
}
const IssueItem = (props: Props) => {
  const { issue, onClick } = props;
  const _imgRef = useRef(null!);

  const onError = (e: any) => {
    e.target.src = imageNotFound;
  };


  if (!issue) {
    return null;
  }

  return (
    <div className="issue" onClick={() => onClick && onClick(issue)}>
      <img
        ref={_imgRef}
        alt={"Issue"}
        loading="lazy"
        data-not-found
        className="img img-fluid img-lg"
        src={issue?.coverUrl}
        onError={onError}
      />

      {/* <div className="info d-flex">
        <div>
          <p className="title ">{issue?.publicationName}</p>
          <p className="published_at">
            {issue?.date
              ? moment(issue?.date).format("ll")
              : "\u00A0"}
          </p>
        </div>
        <div className="ms-auto">
          <span className="far fa-star fa-2x" />
        </div>
      </div> */}
      <div className="info">
        <p className="title ">{issue?.publicationName}</p>
        <p className="published_at">
          {issue?.date
            ? dayjs(issue?.date).format("ll")
            : "\u00A0"}
        </p>
      </div>
    </div>
  );
};


export default IssueItem;

import { useEffect } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { withStores } from "stores";
import queryString from 'query-string'
import { useAppAuthentication } from "stores/authSlice";
import { useAccessTokenMutation, useBadgeInfoMutation, useSearchPersonMutation } from "stores/api/aubureauApi";

const Home = () => {

    const navigate = useNavigate()
    let location = useLocation();
    const seachParams = queryString.parse(location.search)
    const badge = (seachParams.badget_id || seachParams.badge) as string
    const [searchBadgeInfo, { data: badgeData, isLoading: isBadgeLoading, error: badgeError }] = useBadgeInfoMutation()

    const { token } = useAppAuthentication()

    const abloverCode = seachParams.code as string
    const [searchAccessToken, { data: accessTokenData, isLoading: isAccessTokenLoading, error: accessTokenError }] = useAccessTokenMutation()
    // const [searchPerson, { data: searchData, error: searchError }] = useSearchPersonMutation()
    useEffect(() => {
        if (token) {
            navigate("/publications")
        }
    }, [navigate, token])

    useEffect(() => {
        if (badge) {
            searchBadgeInfo(badge)
        }
    }, [badge, searchBadgeInfo])

    useEffect(() => {
        if (abloverCode) {
            searchAccessToken(abloverCode)

        }
    }, [abloverCode, searchAccessToken])


    return (
        <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center" >

            {
                !isBadgeLoading && badgeError && <div className="container">
                    <div className="text-center text-dark-danger">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: badgeError.message
                            }} />
                    </div>
                </div>
            }

            {
                !isAccessTokenLoading && accessTokenError && <div className="container">
                    <div className="text-center text-dark-danger">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: accessTokenError.message
                            }} />
                    </div>
                </div>
            }
            {
                !isAccessTokenLoading && !isBadgeLoading && !accessTokenData && !badgeData &&
                <div className="container">
                    <div className="text-center text-dark-danger">
                        <div >Oups, une erreur s’est produite !</div >
                        <div >Veuillez-vous rendre sur  <a href={process.env.REACT_APP_AB_LOVERS_ACTION}>https://aubureau-lovers.fr</a></div >

                    </div>
                </div>
            }
            {/* 
            {(!token && !badge) && <div className="container">
                <div className="text-center text-dark-secondary">
                    <p>AUCUN COMPTE TROUVÉ AVEC VOTRE NUMERO DE CARTE.</p>
                    <p>VEUILLEZ CONTACTER LE <a href="https://www.aubureau.fr/contact/">SERVICE CLIENT</a>.</p>
                </div>
            </div>} */}
        </div>
    );
}

export default withStores(Home)
import { map } from "lodash";
import { useEffect } from "react";
import IssueItem from "components/IssueItem";
import { Issue, KioskCategory } from "stores/types";
import { withStores, } from "stores";
import { useFetchKioskCategoriesQuery, } from "stores/api/kioskApi";
import { useNavigate } from "react-router-dom";

import { useAppAuthentication } from "stores/authSlice";
import readerUrl from "utils/readerUrl";


interface Props {
    categorize?: boolean
}
const Kiosk = (props: Props) => {

    const { categorize } = props
    const { token } = useAppAuthentication()
    const { data: categories = [], isLoading } = useFetchKioskCategoriesQuery()

    const navigate = useNavigate()

    useEffect(() => {

        if (!token) {
            navigate('/', { replace: true })
        }
    }, [token, navigate])

    const onClick = (issue: Issue) => () => {
        window.location.href = readerUrl(issue);
    }

    const renderCategoryHeader = (category: KioskCategory) => {
        if (!categorize) {
            return
        }
        return (
            <div className="row">
                <div className="col-12">
                    <h4
                        style={{
                            color: `${category?.color || 'var(--bs-primary)'}`,
                            justifyItems: 'center'
                        }}
                    >
                        {category.icon?.uri &&
                            <img
                                src={category.icon.uri}
                                alt={category.name}
                                style={{ width: 40, height: 40 }}
                                className="img-fluid me-2"
                            />
                        }

                        {category.name}
                    </h4>
                    <p>{category.description}</p>
                </div>
            </div>
        );
    }

    const renderIssue = (issue?: Issue) => {

        if (!issue) {
            return null;
        }
        return (
            <div key={issue.id} className="col-6 col-sm-4 col-md-2 p-1">
                <IssueItem
                    issue={issue}
                    onClick={onClick(issue)}
                />
            </div>
        );
    }

    const renderIssues = (issues: Issue[]) => {

        return issues.map(renderIssue)
    }

    return (
        <div >
            <div className="mt-3" />

            {isLoading && categories.length === 0 && (
                <div className="container d-flex justify-content-center align-items-center">
                    <i className="fas fa-spinner fa-spin fa-8x" />
                </div>
            )}


            <div className="container">

                {map(categories, (category) => {
                    return (
                        <div
                            className="category mt-4"
                            key={category.id}
                            id={`category-${category.id}`}
                        >
                            {renderCategoryHeader(category)}
                            <div className="row m-0">
                                {renderIssues(category.items)}
                            </div>
                        </div>
                    );
                })}

            </div>
        </div>
    );
}

export default withStores(Kiosk)
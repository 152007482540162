// Need to use the React-specific entry point to import createApi
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toArray } from 'lodash';
import { Issue, KioskCategory, ListResponse, providesList, providesListWithId, } from '../types';
import { authApi } from './baseApi';
import { userApi } from './userApi';

const apiWithTags = userApi.enhanceEndpoints({ addTagTypes: ['Kiosk', 'UserPublication', 'KioskCategory'] });

const transformIssue = (publicationNumber: any, publication?: any): Issue => ({
    id: publicationNumber.id,
    publicationName: publicationNumber?.publicationName || publication?.name,
    publicationId: publicationNumber?.publicationId || publication?.id,
    coverUrl: publicationNumber.coverUrl,
    pdfUrl: publicationNumber.pdfUrl,
    date: publicationNumber.publishedAt,
    number: publicationNumber.number,
    ojd: publicationNumber.ojd ? {
        ...publicationNumber.ojd,
    } : null,
})
const transformIssues = (data: any[]) => {
    return data.map(transformIssue)
}


export const trackOjd = createAsyncThunk(
    'kiosk/trackOjd',
    async (url: string, thunkAPI) => {
        await fetch(url, {
            signal: thunkAPI.signal,
        })
    }
)
export const trackIssue = createAsyncThunk(
    'kiosk/trackIssue',
    async (id: number) => {
        authApi({ url: `/statistics/${id}`, method: 'post' })
    }
)
export const logIssue = createAsyncThunk(
    'kiosk/logIssue',
    async (issue: Issue, thunkAPI) => {
        thunkAPI.dispatch(trackIssue(issue.id))

        if (issue.ojd && issue.ojd.url) {
            thunkAPI.dispatch(trackOjd(issue.ojd.url))
        }
    }
)
export const kioskApi = apiWithTags.injectEndpoints({
    overrideExisting: false,
    endpoints: (builder) => ({

        fetchKioskCategories: builder.query<KioskCategory[], void>({
            query: () => ({
                url: '/kiosk/categories',
                params: {
                    pdf: true,
                }
            }),
            transformResponse: (response: any[]) => {

                const result = response.reduce((acc, curr) => {
                    acc[curr.id] = {
                        id: curr.id,
                        name: curr.name,
                        position: curr.position || -1,
                        color: curr.color,
                        // icon: curr.icon ? { uri: curr.icon } : null,
                        icon: curr.icon ? { uri: curr.icon.publicWebPath } : null,
                        items: [],
                    }

                    curr.items.forEach((icurr: any) => {
                        if (icurr.publicationNumber) {
                            acc[curr.id].items.push(transformIssue(icurr.publicationNumber))
                        }
                    });
                    return acc
                }, {})
                return toArray(result).sort((a, b) => a.position - b.position);
            },
            providesTags: (result) => [...providesListWithId(result, 'KioskCategory'), 'Profile'],

        }),
        fetchKioskCategory: builder.query<ListResponse<Issue>, number>({
            query: (id) => ({
                url: `/kiosk/categories/${id}`,
                params: {
                    pdf: true,
                }
            }),
            providesTags: (result) => [...providesList(result, 'Kiosk'), 'Profile'],
            transformResponse: (response: any) => ({ ...response, items: transformIssues(response?.results) })

        }),
        fetchLastAddedIssues: builder.query<Issue[], void>({
            query: () => ({
                url: '/kiosk/publication_issues',
                params: {
                    last_added: true,
                    pdf: true,
                }
            }),
            providesTags: (result) => [...providesListWithId(result, 'Kiosk'), 'Profile'],
            transformResponse: (response: any) => transformIssues(response?.results) || response
        }),
        fetchLastPublishedIssues: builder.query<Issue[], void>({
            query: () => ({
                url: '/kiosk/publication_issues',
                params: {
                    last_updated: true,
                    pdf: true,
                }
            }),
            providesTags: (result) => [...providesListWithId(result, 'Kiosk'), 'Profile'],
            transformResponse: (response: any) => transformIssues(response?.results) || response
        }),
        fetchMostViewedIssues: builder.query<Issue[], void>({
            query: () => ({
                url: '/kiosk/publication_issues',
                params: {
                    most_viewed: true,
                    pdf: true,
                }
            }),
            providesTags: (result) => [...providesListWithId(result, 'Kiosk'), 'Profile'],
            transformResponse: (response: any) => transformIssues(response?.results) || response
        }),
        fetchIssues: builder.query<Issue, number>({
            query: (id: number) => ({
                url: `/kiosk/issues/${id}`,
                params: {
                    pdf: true,
                }
            }),
            transformResponse: transformIssue,
        }),
        fetchIssue: builder.query<Issue, number>({
            query: (id: number) => ({
                url: `/publications/issues/${id}`,
                params: {
                    pdf: true,
                }
            }),
            transformResponse: transformIssue,

        }),
        fetchPublicationIssues: builder.query<ListResponse<Issue>, { id: number, page: number | void }>({
            query: ({ id, page }) => ({
                url: `/publications/${id}/issues`,
                params: {
                    pdf: true,
                    page,
                }
            }),
            transformResponse: (response: any) => ({ ...response, items: transformIssues(response?.results) })
        }),
        trackIssue: builder.mutation<void, Issue>({
            query: (issue: Issue) => ({
                url: `/statistics/${issue.id}`,
                method: 'POST',
                params: {
                    pdf: true,
                }
            }),
        }),
        trackOjd: builder.query<void, Issue>({
            query: (issue: Issue) => ({
                url: issue.ojd?.url,
                params: {
                    pdf: true,
                }
            }),
        }),
    }),
})

export const {
    useFetchKioskCategoriesQuery,
    useFetchLastAddedIssuesQuery,
    useFetchMostViewedIssuesQuery,
    useFetchLastPublishedIssuesQuery,
    useFetchIssuesQuery,
    useFetchIssueQuery,
    useFetchPublicationIssuesQuery,
} = kioskApi


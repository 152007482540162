import React from 'react';
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/dist/query'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { authSlice } from './authSlice'
import { api } from './api/api';

import { userApi } from './api/userApi';
import { kioskApi } from './api/kioskApi';
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react';
import { accountMiddleware } from './middlewares/account';
import { tokenMiddleware } from './middlewares/token';
import pkg from '../../package.json';
import { kebabCase } from 'lodash';
import { aubureauApi, aubureauFidepi2Api, aubureauTokenApi } from './api/aubureauApi';

const reducers = combineReducers({
    auth: authSlice.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [kioskApi.reducerPath]: kioskApi.reducer,
    [api.reducerPath]: api.reducer,
    [aubureauApi.reducerPath]: aubureauApi.reducer,
    [aubureauFidepi2Api.reducerPath]: aubureauFidepi2Api.reducer,
    [aubureauTokenApi.reducerPath]: aubureauTokenApi.reducer,
});


const reducer = persistReducer({
    key: kebabCase(pkg.name),
    version: 1,
    storage: storage,
    debug: false,
    whitelist: ['auth',],
}, reducers)


export const store = configureStore({
    reducer: reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(kuntoApi.middleware),
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(
                kioskApi.middleware,
                userApi.middleware,
                api.middleware,
                aubureauApi.middleware,
                aubureauFidepi2Api.middleware,
                aubureauTokenApi.middleware,
                accountMiddleware.middleware,
                tokenMiddleware.middleware,
            )
    },
})

setupListeners(store.dispatch)
// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

let persistor = persistStore(store)

export const withStores = (Component: React.ElementType) => {
    return (props: any): React.ReactElement => {

        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Component {...props} />
                </PersistGate>
            </Provider>
        )

    };
};
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export interface AnyObject {
    [key: string]: any
}
type ImageSourcePropType = {
    [key: string]: any
    uri: string
}
export interface Issue {
    id: number
    publicationName: string
    publicationId: number
    coverUrl: string
    pdfUrl?: string
    date?: string | null
    number?: string | null
    ojd?: {
        url: string
    }
}

export interface ListResponse<T> {

    items: T[]
    currentPage: number
    lastPage: number
    numResults: number
    pageSize: number
}
export function providesList<R extends ListResponse<{ id: number | string }>, T extends string>(
    result: R | undefined,
    tagType: T
) {

    return result && result.items
        ? [
            { type: tagType, id: 'LIST' },
            ...result.items?.map(({ id }) => ({ type: tagType, id })),
        ]
        : [{ type: tagType, id: 'LIST' }]
}
// export function providesListWithId<R extends [{ id: number | string }], T extends string>(
export function providesListWithId<R extends { id: number | string }[], T extends string>(
    result: R | undefined,
    tagType: T
) {

    return result
        ? [
            { type: tagType, id: 'LIST' },
            ...result.map(({ id }) => ({ type: tagType, id })),
        ]
        : [{ type: tagType, id: 'LIST' }]
}

export interface Issue {
    id: number
    publicationName: string
    publicationId: number
    coverUrl: string
    pdfUrl?: string
    status?: string | null
    date?: string | null
    number?: string | null
    ojd?: {
        url: string
    }
}

export interface LibraryItem {
    jobId?: number | string | null
    progress?: number | null
    error?: string | null
    issue: Issue
    completed: boolean
    pageIndex: number,
    downloadedAt: number,
}

export interface Category {
    id: number
    name: string
    position?: number | null
    icon?: ImageSourcePropType
    color?: number | null,
    items: Issue[]
}

export interface Favorite {
    id: number
}

export interface KioskCategory {
    id: number
    name: string
    position?: number | null
    description?: string
    icon?: ImageSourcePropType
    color?: string | null,
    items: Issue[]
}


export interface ToutapprendreProfile {
    [key: string]: any
    id: number | null,
    eta: number | null,
    test?: number | null,
    generic?: number | null,
}

import { createApi, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import axios from 'axios';
import { first, startCase } from 'lodash';
import { userApi } from './userApi';
import hash from 'hash.js'
import { axiosBaseQuery } from './baseApi';

// export const fidepi2Url = axios.create({
//     baseURL: 'https://fidepi2.pi-cloud.eu/scripts/webservice.php',
//     params: {}
// });

const ERROR_MESSAGE = {
    message: [
        "Oups, une erreur s’est produite !",
        "Veuillez-vous rendre sur <a href='" + process.env.REACT_APP_AB_LOVERS_ACTION + "'> https://aubureau-lovers.fr <a/>."
    ]
}

export const aubureauFidepi2Api = createApi({
    reducerPath: 'aubureau_fidepie2_api',
    tagTypes: ['Badge'],
    // baseQuery: fetchBaseQuery({ baseUrl: 'https://fidepi2.pi-cloud.eu/scripts/webservice.php' }),
    baseQuery: axiosBaseQuery({ baseApi: axios.create({ baseURL: 'https://fidepi2.pi-cloud.eu/scripts/webservice.php' }) }),

    endpoints: (builder) => ({
        badgeInfo: builder.mutation<any, string>({
            queryFn: async (badgeId, queryApi, _extraOptions, baseQuery) => {
                const randomResult = await baseQuery({
                    url: '/',
                    method: 'POST',
                    params: {
                        api_id: '3kz0rlc3ct67hvcw',
                        action: 'lecture_badge',
                        badge_id: badgeId,
                    },
                })

                if (randomResult.error) {
                    // return { error: randomResult.error as FetchBaseQueryError }
                    if (randomResult.error) {
                        return {
                            error: { ...ERROR_MESSAGE }
                        }
                    }
                }

                const data = randomResult.data as any

                if (data.code_retour !== 0 && data.code_retour !== -1) {
                    const userBadgetInfo = data.tableau_valeurs;
                    const user = {
                        email: userBadgetInfo.client_email.toLowerCase(),
                        firstName: startCase(userBadgetInfo.prenom.toLowerCase()),
                        lastName: startCase(userBadgetInfo.nom.toLowerCase()),
                        mobilePhone: userBadgetInfo.tel_mobile.toLowerCase(),
                        code: userBadgetInfo.badge_id,
                        civility: !!userBadgetInfo.civilite ? (userBadgetInfo.civilite === "1" ? 'female' : 'male') : null,
                        enabled: true,
                        password: badgeId,
                        plainPassword: badgeId,
                    };
                    // queryApi.dispatch(userApi.endpoints.signin.initiate({
                    //     email: user.email,
                    //     password: user.password,
                    // }))
                    //     .unwrap()
                    //     .catch(err => {
                    //         queryApi.dispatch(userApi.endpoints.signup.initiate(user))
                    //             .unwrap()
                    //             .then(() => {
                    //                 queryApi.dispatch(userApi.endpoints.signin.initiate({
                    //                     email: user.email,
                    //                     password: user.password,
                    //                 }))
                    //             })
                    //     })

                    // try {
                    //     await queryApi.dispatch(userApi.endpoints.signin.initiate({
                    //         email: user.email,
                    //         password: user.password,
                    //     })).unwrap()

                    //     // handle result here
                    // } catch (rejectedValueOrSerializedError) {

                    //     await queryApi.dispatch(userApi.endpoints.signup.initiate(user))

                    //     await queryApi.dispatch(userApi.endpoints.signin.initiate({
                    //         email: user.email,
                    //         password: user.password,
                    //     })).unwrap()


                    // }

                    const Signdata = await queryApi.dispatch(userApi.endpoints.signInOrSignUp.initiate(user)).unwrap()
                    if (!Signdata.token) {
                        return {
                            error: { ...ERROR_MESSAGE }
                        }
                    }
                } else {

                    return {
                        error: { ...ERROR_MESSAGE }
                    }
                }
                return {
                    error: { ...ERROR_MESSAGE }
                }
            },
        }),
    }),
})
export const { useBadgeInfoMutation } = aubureauFidepi2Api


export const aubureauTokenApi = createApi({
    reducerPath: 'aubureau_token_api',
    tagTypes: ['Badge'],
    baseQuery: axiosBaseQuery({ baseApi: axios.create({ baseURL: process.env.REACT_APP_AB_LOVERS_BASE_URL }) }),
    endpoints: (builder) => ({
        accessToken: builder.mutation<any, string>({
            async queryFn(code, queryApi, _extraOptions, baseQuery) {
                const dataRequest = {
                    grant_type: "authorization_code",
                    code: code,
                    client_id: process.env.REACT_APP_AU_BUREAU_LOVERS_CLIENT_ID,
                    secret: process.env.REACT_APP_AU_BUREAU_LOVERS_SECRET,
                    redirect_uri: process.env.REACT_APP_AU_BUREAU_LOVERS_REDIRECT_URI
                }
                const accessTokenResult = await baseQuery({
                    url: '/oauth/access-token',
                    method: 'POST',
                    data: dataRequest,
                })
                if (accessTokenResult.error) {
                    return {
                        error: {
                            ...ERROR_MESSAGE
                        }
                    }
                }

                //let's fetch user information 
                const { access_token } = accessTokenResult.data as any


                const userInfoResult = await baseQuery({
                    url: '/api/user',
                    headers: {
                        'Authorization': `Bearer ${access_token as String}`
                    }
                })

                if (userInfoResult.error) {
                    return { error: userInfoResult.error }
                }


                const userData = userInfoResult.data as { [key: string]: any }
                const gender = userData.gender.toLowerCase() === 'm' ? 'mr' : 'mrs'
                const user = {
                    email: userData.email.toLowerCase(),
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    // mobilePhone: userBadgetInfo.tel_mobile.toLowerCase(),
                    // code: userBadgetInfo.badge_id,
                    // civility: !!userBadgetInfo.civilite ? (userBadgetInfo.civilite === "1" ? 'female' : 'male') : null,
                    gender,
                    enabled: true,
                    password: userData.email.toLowerCase(),
                    plainPassword: userData.email.toLowerCase(),

                }
                try {
                    const data = await queryApi.dispatch(userApi.endpoints.signInOrSignUp.initiate(user)).unwrap()

                    return {
                        data: data
                    }
                    // handle result here
                } catch (rejectedValueOrSerializedError) {

                    return {
                        error: {
                            message: rejectedValueOrSerializedError
                        }
                    }
                }

            },
        }),
    }),
})

export const { useAccessTokenMutation, } = aubureauTokenApi
export const aubureauApi = createApi({
    reducerPath: 'aubureau_api',
    tagTypes: ['Badge'],
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_AU_BUREAU_API }),
    endpoints: (builder) => ({
        useBadgeInfoMutation: builder.mutation<string, void>({
            query: () => ({
                url: '/MDPPublicAPI.svc/rest/token',
                method: 'POST',
                params: {
                    'mdp-user': process.env.REACT_APP_AU_BUREAU_USERNAME,
                    'mdp-password': hash.sha256().update(process.env.REACT_APP_AU_BUREAU_SERCRET).digest('hex'),
                },
            }),
        }),
        getToken: builder.mutation<string, void>({
            query: () => ({
                url: '/MDPPublicAPI.svc/rest/token',
                method: 'GET',
                headers: {
                    'mdp-user': process.env.REACT_APP_AU_BUREAU_USERNAME,
                    'mdp-password': hash.sha256().update(process.env.REACT_APP_AU_BUREAU_SERCRET).digest('hex'),
                },
            }),
            transformResponse: (response: any) => {
                // response.
                // {
                //     "getTokenResult": {
                //         "Token": "1b32fade-527b-460a-b33f-1208d8aafd2e",
                //         "ValidityEndDate": "/Date(1659492380803+0200)/",
                //         "UserGuid": "f3d0a357-4be7-4b20-9b9a-cdda8bf89085",
                //         "ReturnStructure": {
                //             "Code": "OK",
                //             "Messages": [],
                //             "MessageNumber": 0
                //         }
                //     }
                // }
                return response.getTokenResult.Token
            },
        }),
        searchPerson: builder.mutation<any, string>({
            queryFn: async (PersonGUID, queryApi, _extraOptions, baseQuery) => {
                //get token
                // const token = await aubureauApi.endpoints.getToken.initiate()
                const responseToken = await baseQuery({
                    url: '/MDPPublicAPI.svc/rest/token',
                    method: 'GET',
                    headers: {
                        'mdp-user': process.env.REACT_APP_AU_BUREAU_USERNAME,
                        'mdp-password': hash.sha256().update(process.env.REACT_APP_AU_BUREAU_SERCRET).digest('hex'),
                    },
                }) as any;

                const dataToken = responseToken.data.getTokenResult.Token

                const randomResult = await baseQuery({
                    url: '/MDPPublicAPI.svc/rest/searchPerson',
                    method: 'POST',
                    body: {
                        "parameters": [
                            {
                                "AttributeName": "PersonGUID",
                                "Value": PersonGUID,
                                "Comparison": 1
                            }
                        ],
                        "searchOperator": 1,
                        "orderBy": "PersonId",
                        "Skip": 0,
                        "Limit": 1
                    },
                    headers: {
                        "mdp-token": dataToken,
                    }
                })
                if (randomResult.error) {

                    return { error: randomResult.error as FetchBaseQueryError }
                }
                const data = randomResult.data as any

                if (data.searchPersonResult && data.searchPersonResult.PersonList.length === 1) {
                    const userBadgetInfo = first<any>(data.searchPersonResult.PersonList).Person;
                    const user = {
                        email: userBadgetInfo.MainEmailAddress.toLowerCase(),
                        firstName: startCase(userBadgetInfo.FirstName.toLowerCase()),
                        lastName: startCase(userBadgetInfo.LastName.toLowerCase()),
                        mobilePhone: userBadgetInfo.MainMobilePhoneNumber?.toLowerCase(),
                        code: userBadgetInfo.PersonGUID,
                        civility: !!userBadgetInfo.CivilityId ? (userBadgetInfo.CivilityId === "1" ? 'female' : 'male') : null,
                        enabled: true,
                        password: userBadgetInfo.MainEmailAddress.toLowerCase(),
                        plainPassword: userBadgetInfo.MainEmailAddress.toLowerCase(),
                    };
                    // console.log(user)
                    queryApi.dispatch(userApi.endpoints.signin.initiate({
                        email: user.email,
                        password: user.password,
                    }))
                        .unwrap()
                        .catch(err => {
                            queryApi.dispatch(userApi.endpoints.signup.initiate(user))
                                .unwrap()
                                .then(() => {
                                    queryApi.dispatch(userApi.endpoints.signin.initiate({
                                        email: user.email,
                                        password: user.password,
                                    }))
                                })
                        })
                }

                return {
                    error: {
                        error: 'AUCUN COMPTE TROUVÉ AVEC VOTRE NUMERO DE CARTE. '
                    } as FetchBaseQueryError
                }
            },
        }),
    }),
})


export const { useSearchPersonMutation, useGetTokenMutation } = aubureauApi
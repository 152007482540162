// Need to use the React-specific entry point to import createApi
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import axios, { AxiosRequestConfig, AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { camelizeKeys } from 'humps';
import { merge } from 'lodash';
import { store } from 'stores';

export const API_KEY = process.env.REACT_APP_API_SECRETE 
export const API_BASE_URL = process.env.REACT_APP_API_URL || "https://api.lekiosknumerique.com/"!

export const BASE_API_CONFIG = {
    baseURL: API_BASE_URL,
    params: {
        kiosk: process.env.REACT_APP_KIOSK_ID
    },
    headers: {
        locale: 'fr',
        'X-Accept-Version': '3',
    }
}
export const baseApi = axios.create(BASE_API_CONFIG);

// export const authApi = axios.create(merge(BASE_API_CONFIG, { headers: { Authorization: API_KEY } }))
export const authApi = axios.create(merge(BASE_API_CONFIG))



baseApi.interceptors.response.use((response: AxiosResponse) => {

    return {
        ...response,
        data: camelizeKeys(response.data)
    };
}, async error => {
    return Promise.reject(camelizeKeys(error))
})

authApi.interceptors.request.use(function (config) {

    const auth = store.getState().auth
    // @ts-ignore: Unreachable code error
    config.headers['Authorization'] = API_KEY
    if (auth.token) {
        // Authorization: API_KEY
        // @ts-ignore: Unreachable code error
        config.headers['Authorization'] = auth.token
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

authApi.interceptors.response.use(response => {

    return {
        ...response,
        data: camelizeKeys(response.data)
    };
}, async error => {
    return Promise.reject(camelizeKeys(error))
})

export const axiosBaseQuery = (
    { baseApi }: { baseApi: AxiosInstance } = { baseApi: authApi }
): BaseQueryFn<
    AxiosRequestConfig,
    unknown,
    {
        [key: string]: any
        status?: string | number
        data?: any,
        error?: {
            [key: string]: any
            status?: string | number
            data?: any,
        }
    }
> =>
    async (config) => {
        try {
            const result = await baseApi(config)

            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError as AxiosError

            return {
                error: {
                    status: err.response?.status,
                    // @ts-ignore: Unreachable code error
                    message: err.response?.data ? err.response?.data.message : err.message,
                    data: err.response?.data,
                },
            }
        }
    }

import { api } from './api';

export interface SignInProps {
    email?: string | null;
    password?: string | null;
    code?: string | null;
    licence?: string | null;
    [key: string]: any
}

export interface AuthPayLoad {
    refreshToken: string;
    id: number;
    token: string;
}
const apiWithTags = api.enhanceEndpoints({ addTagTypes: ['User', 'Profile'] });

export const userApi = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        signin: builder.mutation<{ [key: string]: any }, SignInProps>({
            query: (data) => ({
                url: '/authentication',
                method: 'POST',
                data: {
                    kiosk: process.env.REACT_APP_KIOSK_ID as string,
                    ...data
                },
            }),
            invalidatesTags: ['Profile']
        }),
        signup: builder.mutation<{ [key: string]: any }, { [key: string]: any }>({
            query: (data) => ({
                url: '/registration',
                method: 'POST',
                data: {
                    kiosk: process.env.REACT_APP_KIOSK_ID as string,
                    ...data
                },
            }),
            invalidatesTags: ['Profile']
        }),
        checkAvailability: builder.mutation<{ available: boolean }, string>({
            query: (code) => ({
                url: `/kiosk_access_codes/${code}/available`, method: 'POST',
            }),
            invalidatesTags: ['Profile']
        }),
        codeToken: builder.mutation<{ token: string }, string>({
            query: (code) => ({
                url: `/kiosk_access_codes/token`,
                method: 'POST',
                data: {
                    code,
                    kiosk: process.env.REACT_APP_KIOSK_ID
                },
            }),
            invalidatesTags: ['Profile']
        }),
        signInOrSignUp: builder.mutation<{ token: string }, { [key: string]: any }>({
            async queryFn(user, queryApi, _extraOptions, baseQuery) {

                let token = ""
                try {
                    const response = await queryApi.dispatch(userApi.endpoints.signin.initiate({
                        email: user.email,
                        password: user.password,
                        overridePasswordCode: true,
                    })).unwrap()
                    // console.log('response', response)
                    token = response.token
                    // handle result here
                } catch (rejectedValueOrSerializedError) {

                    const signReponse = await queryApi.dispatch(userApi.endpoints.signup.initiate(user)).unwrap()
                    if (signReponse.apiKey) {
                        token = signReponse.apiKey
                    }
                    const response = await queryApi.dispatch(userApi.endpoints.signin.initiate({
                        email: user.email,
                        password: user.password,
                        overridePasswordCode: true,
                    })).unwrap()
                    token = response.token
                }

                if (!token) {
                    return {
                        error: {
                            message: "Erreur lor de la connexion"
                        }
                    }
                }

                return { data: { token: token } }
            },
        }),
    }),
})


export const {
    useCheckAvailabilityMutation,
    useCodeTokenMutation,
    useSigninMutation,
} = userApi
// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from './baseApi';

export interface AuthState {
    email?: string | null;
    password?: string | null;
}

export interface AuthPayLoad {
    refreshToken: string;
    id: number;
    token: string;
}
export const api = createApi({
    reducerPath: 'api',
    refetchOnReconnect: true,
    // tagTypes: ['Profile',],
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({

    }),
})


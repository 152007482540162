import Footer from "components/Footer";
import Header from "components/Header";
import React from "react";

import {
  HashRouter as Router,
  // BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from "./screens/Home";
import Kiosk from "./screens/Kiosk";
import { withStores } from "./stores";

const WrapperRouter = ({
  component: Component,
  showHeader,
  showFooter,
  ...rest
}: { component: React.ElementType, showHeader?: boolean, showFooter?: boolean, [key: string]: any }) => (
  <React.Fragment>
    {showHeader && <Header />}

    <main className="flex-shrink-0 min-vh-100">
      <Component {...rest} />
    </main>

    {showFooter && <Footer />}

  </React.Fragment>
);


const App = () => {

  return (
    <Router>
      <Routes>
        <Route index element={<WrapperRouter component={Home} />} />
        <Route path="/publications" element={<WrapperRouter component={Kiosk} showHeader showFooter categorize />} />
        <Route path="/access/:PersonGUID" element={<WrapperRouter component={Home} />} />
        <Route path="*" element={<WrapperRouter component={Home} />} />
      </Routes>
    </Router>
  );
};

export default withStores(App);
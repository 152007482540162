import { Link } from "react-router-dom";
import logo from 'AB_LOGO_Q_BLANC.png'
const Header = () => {

    return (
        <div className="shadow-sm">
            <div className="container">
                <nav className="navbar navbar-expand-lg d-flex justify-content-center navbar-light p-3 px-md-4">
                    <a className="navbar-brand m-0 font-weight-normal" href={process.env.REACT_APP_AB_LOVERS_BASE_URL}>
                        <img src={logo} alt='Logo Au Bureau' className="navbar-brand logo" />
                    </a>
                </nav>
            </div>

        </div>
    )
};


export default Header;
